<template>
  <div class="part-card__wrapper">
    <div class="block block__between block__align-center component__margin-bottom">
      <div class="block block__align-center">
        <div class="part-card__title">Деталь № 212-1</div>
        <div class="component__margin-left">
          <checkbox :value="true" />
        </div>
      </div>
      <div>
        <Trash class="part-card__trash" />
      </div>
    </div>
    <div class="block block__between">
      <div class="part-card__params_container">
        <div class="part-card__title part-card__params_title">
          Параметры детали
        </div>
        <div>
          <div class="block block__between part-card__params">
            <div class="part-card__params_label">Материал детали:</div>
            <div class="part-card__params_value">Сталь 45</div>
          </div>
          <div class="block block__between part-card__params">
            <div class="part-card__params_label">Вес детали:</div>
            <div class="part-card__params_value">137 г.</div>
          </div>
          <div class="block block__between part-card__params">
            <div class="part-card__params_label">Габариты детали:</div>
            <div class="part-card__params_value">69x69x95</div>
          </div>
          <div class="block block__between part-card__params">
            <div class="part-card__params_label">Дата выполнения заказа:</div>
            <div class="part-card__params_value">01.09.2021</div>
          </div>
          <div class="block block__between part-card__params">
            <div class="part-card__params_label">Время изготовления:</div>
            <div class="part-card__params_value">3 дня</div>
          </div>
        </div>
      </div>
      <div class="block block__right">
        <div class="part-card__image_container">
          <img src="@/assets/pictures/basket/test_part.png" class="part-card__image_img" />
        </div>
        <div class="part-card__info_wrapper">
          <div class="part-card__info_container">
            <div class="part-card__info_margin">
              <div class="part-card__info_label">Итого цена за деталь:</div>
              <div class="part-card__info_part-price">3 799,08 ₽</div>
            </div>
            <div class="part-card__info_margin">
              <div class="part-card__info_label">Количество деталей:</div>
              <div>
                <span class="part-card__info_amount">4</span>
                <span class="part-card__info_unit"> шт.</span>
              </div>
            </div>
            <div class="part-card__info_margin">
              <div class="part-card__info_label">Итого цена за партию:</div>
              <div class="part-card__info_total">3 799,08 ₽ </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="part-card__delimeter"></div>
    <div>
      <div class="part-card__title part-card__params_title">
        Параметры изготовления
      </div>
      <div class="block block__between">
        <div>
          <div
            v-for="item in Array(4).keys()" v-bind:key="item"
            class="component__margin-bottom"
          >
            <div class="block block__between" v-if="params[+item]">
              <div class="part-card__manuf-params_title">
                {{ params[+item].label }}
              </div>
              <div class="component__margin-left part-card__manuf-params_value">
                {{ params[+item].value }}
              </div>
            </div>
            <div v-if="params[+item] && params[+item].child.length > 0">
              <div v-for="(child, index) in params[+item].child" v-bind:key="index">
                <div>
                  <span class="part-card__manuf-params_child-label">
                    {{ child.label }}
                  </span>
                  <span class="part-card__manuf-params_child-value">
                    {{ child.value.join(', ') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            v-for="item in Array(4).keys()" v-bind:key="item"
            class="component__margin-bottom"
          >
            <div class="block block__between" v-if="params[+item + 4]">
              <div class="part-card__manuf-params_title">
                {{ params[+item + 4].label }}
              </div>
              <div class="component__margin-left part-card__manuf-params_value">
                {{ params[+item + 4].value }}
              </div>
            </div>
            <div v-if="params[+item + 4] && params[+item + 4].child.length > 0">
              <div v-for="(child, index) in params[+item + 4].child" v-bind:key="index">
                <div>
                  <span class="part-card__manuf-params_child-label">
                    {{ child.label }}
                  </span>
                  <span class="part-card__manuf-params_child-value">
                    {{ child.value.join(', ') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const Checkbox = () => import('@/components/checkbox/CheckBoxI5.vue');
const Trash = () => import('@/assets/pictures/basket/trash_small.svg');

export default {
  name: 'PartCard',
  data() {
    return {
      params: [
        {
          label: 'Материал заготовки',
          value: 'Сталь 45',
          child: [
          ],
        },
        {
          label: 'Вариант исполнения',
          value: 'от исполнителя',
          child: [
          ],
        },
        {
          label: 'Выбор заготовки',
          value: 'Отливка',
          child: [
          ],
        },
        {
          label: 'Метод получения заготовки',
          value: 'ГОСТ 2590-2006',
          child: [
          ],
        },
        {
          label: 'Твёрдость материала заготовки',
          value: '50HRC',
          child: [
            {
              label: 'для поверхностей:',
              value: ['45HRC', '40HRC', '60HRC'],
            },
          ],
        },
        {
          label: 'Шероховатость для всей детали',
          value: 'Ra 3.2',
          child: [
            {
              label: 'для поверхностей:',
              value: ['Ra 2.2', 'Ra 2.2', 'Ra 2.1'],
            },
          ],
        },
      ],
    };
  },
  mounted() {
    console.log(JSON.stringify(this.params));
  },
  components: {
    Checkbox,
    Trash,
  },
};
</script>
